// Here you can add other styles
.inner-nav {
  color: #fff !important;
}
a:hover {
  // color: red;
  text-decoration: none !important;
}
.logo-text {
  color: black;
  font-weight: bold;
  font-size: 38px;
  font-family: "Times New Roman", Times, serif;
}
.logo-tel {
  color: black;
  font-weight: bold;
  font-size: 25px;
  font-family: "Times New Roman", Times, serif;
  // text-decoration: underline;
}
.logo-img {
  position: relative;
}
.logo-img img {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px;
}

.logo-tel::after {
  content: "";
  align-items: center;
  display: block;
  width: 950px;
  padding-top: 10px;
  border-bottom: 5px solid #070707;
}
.print-btn {
  text-align: right !important;
}
@media print {
  .headContent {
    display: inline !important;
  }
  .print-btn {
    visibility: hidden;
  }
  .c-datatable-filter {
    visibility: hidden;
  }
  th > svg {
    visibility: hidden;
  }

  .login-page {
    background-image: url("../assets/images/hos2.jpg");
    background-color: #cccccc;
  }
  .form-control {
    visibility: hidden;
  }
  .c-datatable-items-per-page {
    visibility: hidden;
  }
  .c-header .c-subheader {
    visibility: hidden;
  }
  .c-header.c-header-fixed {
    visibility: hidden;
  }
  .pagination {
    visibility: hidden;
  }
  .more-btn {
    visibility: hidden;
  }
  .top-btn {
    visibility: hidden;
  }
  .c-sidebar-nav {
    visibility: hidden;
  }
  .not-printable-section {
    visibility: hidden;
  }
  .bill-summary-card {
    visibility: hidden;
  }
  tfoot {
    visibility: hidden;
  }
  // .bill-btn {
  //   visibility: hidden;
  // }
}

@media print {
  /* on modal open bootstrap adds class "modal-open" to body, so you can handle that case and hide body */
  body.modal > open {
    visibility: hidden;
  }

  .modal-dialog {
    max-width: 1502px;
    margin: 0;
    // padding-bottom: 100%;
  }

  .modal-backdrop.show {
    opacity: 0;
  }
  // div *:not(.modal) {
  //   // color: red;
  //   visibility: hidden !important;
  // }
  // .modal {
  //   visibility: visible !important;
  // }
  body.modal > open.modal.modal > header,
  body.modal > open.modal.modal > body {
    visibility: visible; /* make visible modal body and header */
  }
}
